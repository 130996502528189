.subtitle {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
}

.arrow {
  font-size: 64px;
}

.buttoncalcular {
  border: 2px solid var(--border-color);
  border-radius: 30px;
  padding: 10px 20px;
  background-size: 200% 100%;
  background-image: linear-gradient(
    266deg,
    rgba(0, 253, 255, 1) 21%,
    rgba(11, 11, 11, 0) 52%
  );
  font-weight: bold;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: white;
  transition: ease-in 0.1s;
}

.buttoncalcular:hover {
  transition: ease-in 0.1s;
  background-size: 100% 100%;
}

.buttoncalcular:disabled {
  opacity: 0.2;
  cursor: default;
}
.buttoncalcular:disabled:hover {
  background-size: 200% 100%;
}

.buttonarrow {
  font-size: 24px;
  font-weight: bold;
  color: var(--accent-color);
  text-decoration: none;
}

.scrollicon {
  font-size: 32px;
  color: var(--bg-color);
  background-color: var(--accent-color);
  border-radius: 50px;
  cursor: pointer;
}

.scrolldown {
  position: fixed;
  bottom: 40px;
  font-size: 12px;
  color: var(--accent-color);
  text-align: center;
  opacity: 0;
  animation: fade-scroll-down 3s ease-in-out 1s infinite alternate forwards;
}

@keyframes fade-scroll-down {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.stack {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  left: 10%;
  width: 500px;
}

.stackicon {
  position: absolute;
  top: 0px;
  right: 40px;
  font-size: 48px;
  color: var(--accent-color);
}
.conocemas {
  background-color: none;
  margin: 40px 0px 0px 0px;
  width: 150px;
}
.stackicontwo {
  position: absolute;
  height: 30px;
  top: 0px;
  right: -40px;
  font-size: 48px;
  color: var(--accent-color);
}

.stacktitle {
  font-size: 90px;
  line-height: 72px;
  margin: 0px;
  padding: 0px;
  position: relative;
  top: 0px;
  left: 0px;
}

.stackcontent {
  position: relative;
  right: 220px;
  top: 140px;
  width: 320px;
  display: flex;
  /* font-size: 16px; */
  /* letter-spacing: 1px; */
}
.pcontent{
  position: absolute;
  width: 320px;
  left: 280px;
  top: 140px;
}

.sectionBackground {
  z-index: -1;
  height: 100vh;
  width: 72vw;
  position: absolute;
  right: 0px;
  top: 0x;
  overflow-x: hidden;
  align-items: flex-end;
  justify-content: flex-start;
  display: flex;
  /* background-size: 100vw, 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/img/man-neon-suit-sits-chair-with-neon-sign-that-says-word-it.jpg); */
}

.sectionBackground img {
  height: 100%;
}

.sectiontwocontainer {
  position: absolute;
  left: 10%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: 360px;
}

.sectiontwotitle {
  font-size: 90px;
  line-height: 72px;
  margin: 0px;
  padding: 0px;
}

.sectiontwocontent {
  display: flex;
  flex-direction: column;
  max-width: 290px;
}

.iconContainer {
  display: inline;
  position: relative;
}
.equis,
.triangle {
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  overflow: hidden;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  text-align: center;
  color: var(--accent-color);
  padding: 0px;
  margin: 0px;
}




.sectiontwocontentrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.sectionGamesOptions {
  justify-content: flex-start;
  align-items:center;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media only screen and (max-width: 900px) {
  .sectionGamesOptions {
    justify-content: start;
    overflow-x: hidden;
  }

  header img,
  p {
    text-align: center;
    width: 300px;
    height: auto;
  }

  .stack {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    padding: 0px;
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    left: 0px;
    top: 0px;
  }

  .sectiontwocontentrow, .sectiontwocontainer {
    position: relative;
    left: 0px;
    flex-direction: column;
    max-width: 300px;
    align-items: flex-start;
  }
  

  .stacktitle {
    font-size: 60px;
    line-height: 52px;
  }

  .stackicon {
    position: absolute;
    text-align: end;
    top: -10px;
    right: -10px;
  }

  .stackcontent,
  .sectiontwocontent p {
    max-width: 290px;
    top: auto;
    right: auto;
    text-align: start;
  }

  .sectiontwotitle {
    font-size: 50px;
    line-height: 52px;
  }

  .sectionBackground {
    position: relative;
    margin-bottom: 20px;
    height: auto;
    max-width: 300px;
    aspect-ratio: 16/9;
  }

  .sectionBackground img {
    position: relative;
    object-fit: cover;
  }

  .sectionCalcula {
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
  }

  .equis,
  .triangle {
    font-size: 16px;
  }

  .stackicontwo {
    top: 0px;
    right: -40px;
    width: 100%;
    text-align: end;
  }
  .conocemas {
    margin: 10px 0px 0px 0px;
  }

  .pcontent{
    position: relative;
    top: 0px;
    left: 0px;
    text-align: left;
  }

  .buttoncalcular {
    background-color: black;
    font-weight: bold;
    font-size: 10px;
    margin: 20px;
  }

  .buttonarrow {
    font-size: 16px;
  }
}
