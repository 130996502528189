.mainmenu {
  position: fixed;
  left: 0px;
  z-index: 3;
  color: var(--accent-color);
  /* text-shadow: 1px 1px black; */
  font-weight: bold;
  width: 100%;
  /* height: 80px; */
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 10%,
    rgba(0, 0, 0, 1) 70%
  );
  transition: top 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.mainmenu ul {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  height: 60px;
}

.mainmenu li {
  list-style-type: none;
  margin: 0px 50px;
}

.mainmenu img {
  height: 32px;
}

.buttoncalcular {
  border: 2px solid var(--border-color);
  border-radius: 30px;
  padding: 10px 20px;
  background-size: 200% 100%;
  background-image: linear-gradient(
    266deg,
    rgba(0, 253, 255, 1) 21%,
    rgba(11, 11, 11, 0) 52%
  );
  font-weight: bold;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: white;
  transition: 100ms ease;
}

.buttoncalcular:hover {
  transition: ease-in 0.1s;
  background-size: 100% 100%;
}

.buttonarrow {
  font-size: 32px;
  font-weight: bold;
  color: var(--accent-color);
  text-decoration: none;
}

.hamburguerContainer {
  z-index: 4;
  width: 36px;
  height: 36px;
  position: fixed;
  right: 30px;
  top: 40px;
  display: none;
  background-color: black;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  border-radius: 50%;
}
.hamburguer {
  width: 20px;
  height: 4px;
  background-color: white;
  transition: ease-in 0.15s;
  border-radius: 1px;
}

.one {
  position: absolute;
  top: 10px;
}
.two {
  position: absolute;
  top: 16px;
}
.three {
  position: absolute;
  top: 22px;
}
.translate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80px;
  position: relative;
  background-color: black;
  padding: 0px 0px;
}
.translate:hover {
  background-size: 200% 100%;
}
.languageOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  width: 40px;
  height: 20px;
  overflow: none;
  text-align: center;
  z-index: 1;
  transition: 300ms ease;
}
.translateSelection {
  position: absolute;
  left: 0px;
  height: 20px;
  width: 40px;
  border: 2px solid var(--accent-color);
  border-radius: 30px;
  transition: 300ms ease-in-out;
  background-color: var(--accent-color);
  color: black;
  z-index: 0;
}
@media only screen and (max-width: 900px) {
  .hamburguerContainer {
    display: flex;
  }
  .translate {
    width: 200px;
    height: 40px;
  }
  .languageOption {
    font-size: 32px;
    width: 70px;
    /* background-color: tomato; */
  }
  .translateSelection {
    width: 70px;
    height: 40px;
  }

  .mainmenu {
    background-image: url("../../assets/img/menu-web.jpg");
    background-size: cover;
    height: 100vh;
    padding-top: 0px;
    margin: 0px;
    overflow-x: hidden;
  }

  .mainmenu ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-top: 40px;
    height: 40vh;
  }

  .mainmenu li {
    width: 260px;
    font-size: 32px;
    margin: 20px 0px;
    border-bottom: 2px solid gray;
  }

  .buttoncalcular {
    border: none;
    justify-content: flex-start;
    font-size: 32px;
    margin: 0px;
    color: var(--accent-color);
    padding: 0px;
    background-size: 300% 100%;
    background-image: none;
    border-radius: 0px;
  }

  .buttoncalcular:hover {
    background-size: 300% 100%;
  }
}
