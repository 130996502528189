.sectionregistrocorreo {
  align-items: center;
  overflow-x: hidden;
}
.container {
  display: flex;
  flex-direction: row;
  max-width: 1400px;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 20px;
}
.title {
  font-size: 6rem;
  margin: 0px;
  line-height: 6rem;
  font-weight: 100;
  text-transform: uppercase;
}
.dialogregistro form{
  width: 100%;
}
.dialogregistro img{
  /* background-color: green; */
  height: 140px;
  margin-bottom: 40px;
}
.dialogregistro {
  padding: 30px;
  color: var(--accent-color);
  border: 2px solid var(--border-color);
  background-color: var(--bg-color);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  overflow: hidden;
  width: 50vw;
}
.divider{
  width: 100px;
}
.rowregistro{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px 0px;
}
@media only screen and (max-width: 900px) {
  .container{
    flex-direction: column;
  }
  .title{
    font-size: 3rem;
    line-height: 3rem;
  }
.dialogregistro{
  margin-top: 20px;
  padding: 0px 20px;
  width: calc(100vw - 80px);
  background-color: rgba(0, 0, 0, 0);
}
.rowregistro{
  margin-top: 10px;
}
  .dialogregistro img{
    height: 60px;
    margin-bottom: 20px;
  }
}