.layout-game-options{
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
}
.navigation{
    width: 280px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--accent-color);
    z-index: 101;
}
.navigation img{
    height: 60px;
}
.navigation button{
    font-size: 18px;
    height: 40px;
    font-family: grotesk;
    font-weight: bold;
    color: gray;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.navigation button span{
    margin-left: 10px;
}
.navigation button:hover{
    cursor: pointer;
    color: var(--accent-color);
}
.navigation button:focus{
    cursor: pointer;
    color: var(--accent-color);
}
.gsSection table{
    color: var(--accent-color);
    text-align: center;
}
.gsSection table td{
    padding: 4px 20px;
    color: gray;
}
.gsSection, .navigation {
    height: 100vh;
}
.gsSection {
    margin: 0px;
    text-align: center;
    width: calc(100vw - 280px);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.grid-items {
    width: 360px;
    margin: 40px;
}

.grid-items img {
    height: 24px;
    margin-right: 20px;
}

.label-value-item {
    font-size: 12px;
    margin: 0px;
}

.value-estimate {
    flex-direction: column;
    padding: 8px;
}
/* .valor-estimado{
    line-height: 28px;
    margin-bottom: 8px;
} */
.descargas-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}